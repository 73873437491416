import * as React from 'react';
import * as R from 'ramda';

const getFirstUnfinished = (children) => R.findIndex((child) => !(child.props.completed === true), children);

const getChildren = (children) => children.filter((c) => !!c);

class CollapsibleGroupComponent extends React.Component {
  constructor(props) {
    super();

    const children = Array.isArray(props.children) ? getChildren(props.children) : [props.children];

    this.state = {
      open: props.open || getFirstUnfinished(children),
      status: children.map((element, index) => ({
        index,
        title: element.props.title,
        completed: element.props.completed || false,
        optional: element.props.optional || false,
      })),
    };

    this.handleClose = (e) => {
      const { open } = this.state;

      e.stopPropagation();

      this.setState({ open: getChildren(this.props.children).length - 1 === open ? null : open + 1 });
    };
    this.handleGetStatus = this.handleGetStatus.bind(this);
  }

  componentDidMount() {
    const { open } = this.state;

    try {
      const { id } = this.props.children[open].props; // eslint-disable-line react/prop-types
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    } catch (err) {
      // Nothing bad, just ignore it
    }
  }

  static props;


  handleGetStatus() {
    return this.state.status;
  }

  handleComplete = (index) => {
    const { onStatusChange } = this.props;

    this.state.status[index].completed = true;

    if (onStatusChange) onStatusChange();
  };

  handleGoToStep(index) {
    this.setState({ open: index });
  }

  render() {
    const { children } = this.props;

    const elements = getChildren(React.Children.toArray(children)).map((element, index) => element && React.cloneElement(element, {
      index,
      open: index === this.state.open,
      onToggle: () => this.setState({ open: this.state.open === index ? null : index }),
      onClose: this.handleClose,
      onComplete: this.handleComplete,
    }));

    return (
      <div className="CollapsableGroup">
        {elements}
      </div>
    );
  }
}

export default CollapsibleGroupComponent;
