import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { getFormSyncErrors } from 'redux-form';
import { Trans } from 'react-i18next';

import { hasErrorByFields, showValidationErrorsForStep } from '@common/utils/validation';
import { Button } from '../button';
import Icon from '../icon';
import Group from './group';
import CollapsibleButton from './button';

const CollapsibleColumnComponent = ({ children, flex = 1, grey, padding }) => {
  const classNames = ['Collapsible__Column'];
  if (grey) classNames.push('Collapsible__Column--grey');
  if (padding) classNames.push(`Collapsible__Column--padding-${padding}`);

  return <div className={classNames.join(' ')} style={{ flex }}>{children}</div>;
};

class CollapsibleComponent extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isOpen: props.open || false,
      hasError: false,
      isCompleted: props.completed || false,
    };

    this.contentRef = null;

    this.handleToggle = () => this.setState({ isOpen: !this.state.isOpen });
    this.setReference = (ref) => (this.contentRef = ref);
    this.calculateContentHeight = () => {
      const { isOpen } = this.state;
      const { open } = this.props;

      const isItemOpen = open !== undefined ? open : isOpen;

      if (this.contentRef && this.contentRef.parentNode) {
        this.contentRef.parentNode.style.height = isItemOpen ? `${this.contentRef.offsetHeight}px` : '0px';
      }
    };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      open, completed, index, onComplete, onOpen, errors, touch, fields,
    } = this.props;

    if (
      (this.state.isOpen && !nextState.isOpen)
      || (nextProps.completed === undefined && open && !nextProps.open)
    ) {
      if (errors && fields?.length) {
        this.state.hasError = hasErrorByFields(errors, fields);
      }
      this.state.isCompleted = true;
      if (onComplete) onComplete(index);
    }

    if (nextProps.completed !== undefined && completed !== nextProps.completed) {
      this.state.isCompleted = !!nextProps.completed;
      if (nextProps.completed && onComplete) onComplete(index);
    }

    if ((!this.props.open && nextProps.open) || (!this.state.isOpen && nextState.isOpen)) {
      if (onOpen) onOpen(index);
      if (errors && touch && fields?.length) {
        showValidationErrorsForStep(errors, fields, touch);
      }
    }
  }

  static props;
  static Group;
  static Button;
  static Column;

  contentRef;

  render() {
    const { isCompleted, hasError } = this.state;
    const {
      id, title, children, className, index, open, invisible, onToggle, onClose,
    } = this.props;

    if (invisible) return null;

    const isOpen = open !== undefined ? open : this.state.isOpen;

    const classNames = ['Collapsible'];
    if (isOpen) classNames.push('Collapsible--open');

    return (
      <CSSTransition
        in={isOpen}
        timeout={250}
        classNames="Collapsible"
        onEntering={this.calculateContentHeight}
        onExiting={this.calculateContentHeight}
      >
        {(state) => {
          const contentClassNames = ['Collapsible__Content'];
          if (state === 'entered') contentClassNames.push('Collapsible__Content--entered');

          return (
            <div id={id} className={classNames.join(' ')} key="collapsible">
              <div className="Collapsible__Header" onClick={onToggle || this.handleToggle}>
                {!isOpen && isCompleted && !hasError && (
                  <div className="Collapsible__Header__Completed">
                    <Icon type="check" />
                  </div>
                )}
                {!isOpen && isCompleted && hasError && (
                  <div className="Collapsible__Header__Completed--invalid">
                    <Icon type="warning" />
                  </div>
                )}
                {(!isCompleted || isOpen) && <div className="Collapsible__Header__Index">{index + 1}</div>}
                <h3>{title}</h3>
                {isOpen && (isCompleted || this.props.completed === undefined) && (
                  <Button size="small" type="white" onClick={onClose}>
                    <Trans i18nKey="common:collapsible_done" />
                  </Button>
                )}
              </div>
              <div className={contentClassNames.join(' ')} style={{ overflow: state === 'entered' ? 'initial' : 'hidden' }}>
                <div
                  key="content"
                  className={`Collapsible__Content__Inner${className ? ` ${className}` : ''}`}
                  ref={this.setReference}
                >
                  {children}
                </div>
              </div>
            </div>
          );
        }}
      </CSSTransition>
    );
  }
}

CollapsibleComponent.Group = Group;
CollapsibleComponent.Button = CollapsibleButton;
CollapsibleComponent.Column = CollapsibleColumnComponent;

const mapStateToProps = (state, { form = '' }) => {
  return {
    errors: form ? getFormSyncErrors(form)(state) : null,
  };
};

export default connect(mapStateToProps)(CollapsibleComponent);
