import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';

import { PredicatesFilter } from '@common/components/predicates-filter';

const fieldWrapper = <P extends Record<string, any>, C = React.ComponentType<any>>(component: C) => (
  props: BaseFieldProps & Omit<P, keyof WrappedFieldProps>,
) => (
  <Field {...props} component={component} />
);

type AudienceProps = React.ComponentProps<typeof PredicatesFilter>;

export const AudienceInput = fieldWrapper<AudienceProps>(PredicatesFilter);
