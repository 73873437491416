import * as React from 'react';
import { Trans } from 'react-i18next';

const CollapsibleButton = ({ index, title, onClick }) => (
  <div className="CollapsibleButton">
    <div className="CollapsibleButton__Index">{index + 1}</div>
    <div className="CollapsibleButton__Title">{title}</div>
    {onClick && <a onClick={onClick}><Trans i18nKey="common:collapsible_button_open" /></a>}
  </div>
);

export default CollapsibleButton;
